* {
  font-family: "articulat-cf", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1 {
  font-family: "forma-djr-banner", sans-serif;
  font-weight: 700;
}
